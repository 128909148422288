import React from 'react';
import { Container, Col, Row, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import "./footer.css";
import { BsFillTelephoneFill } from 'react-icons/bs';

import { BsFacebook } from 'react-icons/bs';
import { BsTwitter } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { BsYoutube } from 'react-icons/bs';
import { IoIosMail } from 'react-icons/io';

import { BsFillTelephoneForwardFill } from 'react-icons/bs';
import { TbDeviceLandlinePhone } from 'react-icons/tb';
import { IoLogoWhatsapp } from 'react-icons/io';

function Footer() {
    return (
        <div>
            <div className='footer'>

                <Container>
                    <div className='pointicon'>
                    <div class="float-sm">
                        <div class="f2-f2 float-ap">

                            {/* <a href="#" data-bs-toggle="modal" data-bs-target="#enquiry_modal"> */}
                                <a href="https://wa.me/+919845255395\" target="_blank"><IoLogoWhatsapp className='whatsapp' /></a>

                            {/* </a> */}
                            <a href="tel:+919845255395" target="_blank">
                                <a href="tel:+919845255395"><BsFillTelephoneForwardFill className='telee' /></a>

                            </a>


                        </div>
                    </div>
                    </div>

                    <Row>
                        <Col md={4}>
                            <div>
                                <h6>ADDRESS</h6>
                                <p></p>
                                <p>FIMAGS CORPORATE ADVISORS P.LTD<br></br>#26/4,1 ST B CROSS,<br></br>SARASWATHIPURAM,<br></br>BANGALORE 560076.</p>

                                



                            </div>
                            <div>
                                <p><BsFillTelephoneFill /> +91 98452 55395</p>
                                <p ><TbDeviceLandlinePhone className='fonts20p'/>+91 80 35552625</p>
                                <p ><IoIosMail className='fonts20p'/>info@fimagscorporate.com</p>
                                {/* <a href="tel:05384637335">0 538 463 73 35</a> */}
                            </div>
                            <div>
                                <h6>Social Media</h6>
                                <div>
                                    <a href='https://www.facebook.com/' target="_blank"><BsFacebook className='icon' /></a> <a href='https://twitter.com/home' target="_blank"><BsTwitter className='icon' /></a> <a href='https://www.linkedin.com/' target="_blank"><BsLinkedin className='icon' /></a> <a href='https://youtube.com/' target="_blank"><BsYoutube className='icon' /></a>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div>
                                <h6>Our Services</h6>
                                <ul>
                                    <li><a href="/services">Financial Information Services</a></li>
                                    <li><a href="/services#advisory">Financial Advisory Services</a></li>
                                    <li><a href="/services#Intermediary">Financial Intermediary</a></li>
                                    <li><a href="/services#Accounting">Accounting services</a></li>
                                    <li><a href="/services#Tax">Tax consultation and compliance</a></li>
                                    <li><a href="/services#consultation">Business consultation</a></li>
                                    <li><a href="/services#Startup">Startup advisory</a></li>
                                </ul>
                                {/* <div className='socialicon'>
                                    <a href="https://www.whatsapp.com/" target="_blank"><IoLogoWhatsapp className='whatsapp' /></a>   <a href="tel:05384637335"><BsFillTelephoneForwardFill className='telee' /></a>
                                </div> */}
                                {/* <div className="image"> 
                            <img src={require("../../assets/social_foot_icon_1.png")} loading="lazy" alt="WhatsApp" class="img-fluid"/> 
                        </div> */}
                            </div>
                        </Col>
                        <Col md={4}>
                            <div>
                                <h6>NPA Advisory</h6>
                                <ul>
                                    <li><a href="/npa-advisory">NPA Management</a></li>
                                    <li><a href="/npa-advisory">Advise for recovery suits/DRT/SARFAESI/NCLT</a></li>
                                    <li><a href="/npa-advisory">Mergers and acquisitions</a></li>
                                    <li><a href="/npa-advisory">Short term finance</a></li>

                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>

            <div className='footer2'>
                <Container>
                    <p className='copright'>Copyright © 2023 FIMAGS CORPORATE ADVISORS P LTD </p>
                </Container>

            </div>



        </div>

    )
}

export default Footer
