import React, { useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import "./aboutus.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


function Services() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    return (
        <div>

            <div className='aboutusbanner'>
                <h2>About Us</h2>
            </div>

            <div className='backgroundcolor'>


                <div className='services' id="consultation">
                    <Container>
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <img style={{ width: "100%" }} src={require("../../assets/aboutz.png")} />
                            </Col>
                            <Col md={6} data-aos="fade-up" data-aos-duration="3000">
                                <div>
                                    <h4>About Us</h4>
                                    <p> The company is founded in 2017 by a group of Experienced bankers and Finance professionals to provide Advisory services in the financial management  and Business  counselling for mid-corporates, MSMEs and other businesses.
                                    </p>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>

                <div className='founders'>
                    <Container>
                        <h2>Founders & Directors</h2>
                        <Row>
                            <Col md={4}>
                                <div class="portfolio gallery">
                                    <div class="item">
                                        <div class="thumb">
                                            <img src={require("../../assets/client1.png")} alt="" />
                                        </div>
                                        <div class="text">
                                            <h3><a href="#">R.SAMPATH KUMAR M.COM.,CAIIB</a></h3>
                                            <h6>CO-FOUNDER &amp; MANAGING DIRECTOR</h6>
                                            <p>FORMER GENERAL MANAGER CANARABANK HAVING FINANCIAL FIELD EXPERIENCE OF 46 YEARS AND
                                                AS FRONT LINE BANKER FOR 38 YEARS. HEALTHCARE FINANCE AND DIGITAL HEALTHCARE ARE NICHE
                                                SUBJECTS. MENTOR FOR MID AND SMALL CORPORATES FOR FINANCE,BUSINESS PROMOTION &amp;
                                                MARKETING, BUSINESS RESTRUCTURING AND REHABILITATION.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div class="portfolio gallery">
                                    <div class="item">
                                        <div class="thumb">
                                            <img src={require("../../assets/client2.png")} alt="" />
                                        </div>
                                        <div class="text">
                                            <h3><a href="#">R.GAVIRENGASWAMY M.SC (IND.CHEMISTRY)CAIIB, PGDCA</a></h3>
                                            <h6>CO-FOUNDER &amp; EXECUTIVE DIRECTOR</h6>
                                            <p>FORMER ASST.GENERAL MANAGER,CANARABANK HAVING VAST FINANCIAL FIELD EXPERIENCE OF 45
                                                YEARS AND AS A SENIOR BANKER FOR38 YEARS. AN EDII ACCREDITED TRAINER-MOTIVATOR IN 
                                                ENTREPRENEUR DEVELOPMENT AND BUSINESS COUNSELLOR. SPECIALIST IN WOMEN
                                                EMPOWERMENT,SOCIAL BAKING MSME, , RURAL CREDIT AND NPA MANAGEMENT. </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div class="portfolio gallery">
                                    <div class="item">
                                        <div class="thumb">
                                            <img src={require("../../assets/client3.png")} alt="" />
                                        </div>
                                        <div class="text">
                                            <h3><a href="#">G.NAGARAJU B.COM.,PG DIP(INDUSTRIAL CO-OPERATION)</a></h3>
                                            <h6>CO-FOUNDER &amp; DIRECTOR</h6>
                                            <p>FORMER ASST.DIRECTOR ,DEPT.OF INDUSTRIES AND COMMERCE GOVT OF KARNATAKA. EXPERIENCED
                                                IN INDUSTRIES PROMOTION, FORMATION OF INDUSTRIAL ESTATES&amp; FOOD PARKS AND LIAISONING
                                                WITH VARIOUS GOVT DEPARTMENTS. FORMER OSD TO MSME MINISTER , UNION GOVERNMENT OF
                                                INDIA. </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>



                        </Row>
                        <Row>
                            <Col md={6}>
                                <div class="portfolio gallery ">
                                    <div class="item">
                                        <div class="thumb">
                                            <img src={require("../../assets/client4.png")} alt="" />
                                        </div>
                                        <div class="text paddfounder">
                                            <h3><a href="#"> G.CHANDRASEKAR B.SC,CAIIB</a></h3>
                                            <h6>DIRECTOR</h6>
                                            <p>FORMER CANARA BANK MANAGER HAVING VAST FIELD EXPERIENCE OF 33 YEARS. EXPERT IN CREDIT
                                                ANALYSIS,CREDIT DISPENSATION AND PUBLIC RELATION. </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div class="portfolio gallery">
                                    <div class="item">
                                        <div class="thumb">
                                            <img src={require("../../assets/client5.png")} alt="" />
                                        </div>
                                        <div class="text">
                                            <h3><a href="#">R.RAJAKUMARAN M.COM., CAIIB., CMA., ACS.,</a></h3>
                                            <h6>ASSOCIATE</h6>
                                            <p>FORMER DEPUTY GENERAL MANAGER,CANARABANK HAVING BANKING EXPERIENCE OF38 YEARS IN
                                                FRONT LINE BANKING AND COMPANY SECRETARY CANBANKSECURITIES LTD. AS A PROFESSIONAL
                                                COMPANY SECRETARY AND CMA, ADVISOR IN CORPORATE FORMATION, CORPORATE GOVERNANCE,
                                                COST SAVING, PROFITABILITY AND PRODUCTIVITY. EXPERT CONSULTANT IN GST MATTERS.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>




                {/* <div className='advisor'>
                    <Container>
                        <h2>Advisor to the Board</h2>
                        <Row>
                            <Col md={4}>
                                <figure class="snip1390">
                                    <img src={require("../../assets/profile2.jpg")} alt="profile-sample3" class="profile" />
                                    <figcaption>
                                        <h2>Kevin Peterson</h2>
                                        <h4>Experienced</h4>
                                        <blockquote>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</blockquote>
                                    </figcaption>
                                </figure>
                            </Col>
                            <Col md={4}>
                                <figure class="snip1390">
                                    <img src={require("../../assets/profile.jpg")} alt="profile-sample3" class="profile" />
                                    <figcaption>
                                        <h2>Johnson</h2>
                                        <h4>Experienced</h4>
                                        <blockquote>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</blockquote>
                                    </figcaption>
                                </figure>
                            </Col>
                            <Col md={4}>
                                <figure class="snip1390">
                                    <img src={require("../../assets/profile1.jpg")} alt="profile-sample3" class="profile" />
                                    <figcaption>
                                        <h2>Victor</h2>
                                        <h4>Experienced</h4>
                                        <blockquote>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</blockquote>
                                    </figcaption>
                                </figure>
                            </Col>

                        </Row>
                    </Container>
                </div> */}




            </div>

        </div>

    )
}

export default Services;
