import React, { useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import "./virutual.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


function Services() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    return (
        <div>

            <div className='virutualbanner'>
                <h2>Virtual CFO</h2>
            </div>

            <div className='backgroundcolor'>

                <div className='virutual'>
                    <Container>
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <div>
                                    <h3>Virtual CFO</h3>
                                    <p>Chief Finance officer role in corporates and MSMEs is very important to scale new heights but it is not affordable for Small & Mid Corporates and MSMEs when they  are in growing stage, . We offer entire gamut of CFO services with,
                                       <br></br>
                                        <ul style={{ marginTop:"10px" }}>
                                            <li>Financial planning</li>
                                            <li>Financial Management/Banking</li>
                                            <li>Accounts & Audit requirements </li>
                                            <li>Cost savings</li>
                                            <li>Tax compliance</li>
                                        </ul>

                                        Our Virtual CFO is virtually in your office taking care of financial management and meeting whenever necessary. The entrepreneur can concentrate more on development of business leaving the financial worries to our Virtual CFO.

                                    </p>
                                </div>
                            </Col>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <img style={{ width: "100%" }} src={require("../../assets/virutual.jpg")} />
                            </Col>
                        </Row>
                    </Container>
                </div>


            </div>

        </div>

    )
}

export default Services;
