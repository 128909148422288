import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import "./partner.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BsFillPersonFill } from 'react-icons/bs';
import { BsTelephonePlus } from 'react-icons/bs';
import { MdOutlineMailOutline } from 'react-icons/md';
import { MdLocationPin } from 'react-icons/md';
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from '@emailjs/browser';

function Partner() {

    const initialValues = { username: "", email: "", phonenumber: "", address: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e, type) => {


        console.log("dsfsdf", e.target.value)

        if (type == "username") {
            var data = { ...formValues }
            data.username = e.target.value
            setFormValues(data)
        }
        else if (type == "email") {
            var data = { ...formValues }
            data.email = e.target.value
            setFormValues(data)
        }
        else if (type == "phonenumber") {
            var data = { ...formValues }
            data.phonenumber = e.target.value
            setFormValues(data)
        }
        else if (type == "address") {
            var data = { ...formValues }
            data.address = e.target.value
            setFormValues(data)
        }
        // setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        // window.open('mailto:info@fimagscorporate.com?subject='+"sasisiiiiii"+ "&body="+"thasssssssssss");

      
        // e.preventDefault();
        setFormErrors(validate(formValues));
        if (formValues.address && formValues.phonenumber && formValues.username && formValues.email) {
            const templateParams = {
                username: formValues.username,
                address: formValues.address,
                email:formValues.email,
                phonenum:formValues.phonenumber
            };
            emailjs.send('service_n8kml287', 'template_8agoa4a', templateParams, 'f8eZM-pYGlWPBsh7Q')
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                }, (err) => {
                    console.log('FAILED...', err);
                });
            // var data123 = {...formValues}
            //  data123.address="",
            //  data123.email="",
            //  data123.phonenumber='',
            //  data123.username=''
            //  setFormValues(data123)


            swal({
                title: "Send the Request Successfully.",
                icon: "success",

            }).then(function () {
                window.location.reload()
            });

        }


        // setIsSubmit(true);
    };

    // useEffect(() => {
    //     console.log(formErrors);
    //     if (Object.keys(formErrors).length === 0 && isSubmit) {
    //         console.log(formValues);
    //     }
    // }, [formErrors]);



    const validate = (values) => {

        console.log(values, "bbbbbbbbbbbb")

        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        // const regex1 =  new RegExp(/"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"/gmi);

        if (!values.username) {
            errors.username = "Username is required!";
        }
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        if (!values.phonenumber) {
            errors.phonenumber = "Phonenumber is required!";
        }
        // if (!values.phonenumber) {
        //     errors.phonenumber = "Phone Number is required!";
        // } else if (!regex1.test(values.phonenumber)) {
        //     errors.phonenumber = "This is not a valid Mobile Number!";
        // }

        if (!values.address) {
            errors.address = "Address is required!";
        }

        return errors;
    };


    const key = "6Le5z1olAAAAAIWBdv2B_hEWIs6JorWqVVYNK-sD";
    const [captchavalue, setcaptchavalue] = useState(false);

    function onChangerecaptcha(value) {
        console.log("Captcha value");
        setcaptchavalue(true);
    }


    useEffect(() => {
        AOS.init({
            duration: 2000
        });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    return (
        <div>

            <div className='parnerbanner'>
                <h2>Partner With Us</h2>
            </div>

            <div className='backgroundcolor'>

                <div className='virutual partners' style={{ padding: "2% 0%" }}>
                    <Container>
                        <div>
                            <Row>
                                <Col md={12}>
                                    <p>Are you a Practicing Chartered Accountant/Company Secretary/Cost Accountant/Tax consultant/Accounts maintenance firm in Karnataka or Tamilnadu.. Join as associates with us.</p>
                                </Col>
                            </Row>
                            <Row style={{ alignItems: "center" }}>
                                <Col md={6} >
                                    <img style={{ width: "100%", borderRadius: "8px" }} src={require("../../assets/partner.jpg")} />
                                </Col>
                                <Col md={6} >
                                    <h1 class="partner-p">Partner With Us</h1>
                                    <div>
                                        <form>
                                            <div class="form-group position-relative">
                                                <label for="formName" class="d-block">
                                                    {/* <i class="fa fa-square"></i> */}
                                                    <BsFillPersonFill className='iconzz' />
                                                </label>
                                                <input type="text" id="formName" class="form-control form-control-lg thick" placeholder="Name" value={formValues.username}
                                                    onChange={(e) => handleChange(e, "username")} />
                                                <p className='errormsg'>{formErrors.username}</p>
                                            </div>


                                            <div class="form-group position-relative">
                                                <label for="formEmail" class="d-block">
                                                    {/* <i class="icon" data-feather="mail"></i> */}
                                                    <MdOutlineMailOutline className='iconzz' />
                                                </label>
                                                <input type="email" id="formEmail" class="form-control form-control-lg thick" placeholder="E-mail" value={formValues.email}
                                                    onChange={(e) => handleChange(e, "email")} />

                                                <p className='errormsg'>{formErrors.email}</p>
                                            </div>

                                            <div class="form-group position-relative">
                                                <label for="formEmail" class="d-block">
                                                    {/* <i class="icon" data-feather="mail"></i> */}
                                                    <BsTelephonePlus className='iconzz' />
                                                </label>
                                                <input type="text" id="formphonenumber" class="form-control form-control-lg thick" placeholder="Phone Number" value={formValues.phonenumber}
                                                    onChange={(e) => handleChange(e, "phonenumber")} />

                                                <p className='errormsg'>{formErrors.phonenumber}</p>
                                            </div>

                                            <div class="form-group position-relative">
                                                <label for="formEmail" class="d-block">
                                                    {/* <i class="icon" data-feather="mail"></i> */}
                                                    <MdLocationPin className='iconzz' />
                                                </label>
                                                <input type="text" id="formaddress" class="form-control form-control-lg thick" rows="4" placeholder="Address" value={formValues.address}
                                                    onChange={(e) => handleChange(e, "address")} />

                                                <p className='errormsg'>{formErrors.address}</p>
                                            </div>
                                            {/* <div class="form-group message">
                                            <textarea id="formMessage" class="form-control form-control-lg" rows="5" placeholder="Address"></textarea>
                                        </div> */}
                                            <ReCAPTCHA
                                                sitekey={key}
                                                onChange={onChangerecaptcha}
                                            />

                                            <div class="text-center">
                                                <button onClick={handleSubmit} type="button" class="btn btn-primary" tabIndex="-1">Send message</button>
                                            </div>
                                        </form>

                                    </div>
                                </Col>
                                <Col md={12}>
                                    <p className='partner-last'>We provide <span style={{ fontWeight: "600" }}>CORPORATE DSA</span> service for Banks and Financial Institutions. Partner with us for suitable proposal .
                                    </p>
                                </Col>

                            </Row>
                        </div>
                    </Container>
                </div>


                {/* <div className='contact2'>
                    <Container>
                        <div>
                            <Row>
                                <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                    <img style={{ width: "100%", borderRadius: "8px" }} src={require("../../assets/partner.jpg")} />
                                </Col>
                                <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                    <form action="#">
                                        <div class="social-container">
                                            <a href="#" class="social"><i class="fab fa-facebook-f"></i></a>
                                            <a href="#" class="social"><i class="fab fa-google-plus-g"></i></a>
                                            <a href="#" class="social"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                        <input type="text" placeholder="Name *" />
                                        <input type="email" placeholder="Email *" />
                                        <input type="text" placeholder="Company" />
                                        <input type="phone" placeholder="Phone" />
                                        <textarea rows="4" placeholder="Message"></textarea>
                                        <button>Submit</button>
                                    </form>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                <div className='contact2'>
                    <div class="container">
                        <div class="form-container">
                            <div class="left-container">
                                <div class="left-inner-container">
                                    <h2>Let's Chat</h2>
                                    <p>Whether you have a question, want to start a project or            simply want to connect.</p>

                                    <p>Feel free to send me a message in the contact form</p>
                                </div>
                            </div>
                            <div class="right-container">
                                <div class="right-inner-container">
                                    <form action="#">
                                        <h2 class="lg-view">Contact</h2>
                                        <h2 class="sm-view">Let's Chat</h2>
                                        <p>* Required</p>
                                        <div class="social-container">
                                            <a href="#" class="social"><i class="fab fa-facebook-f"></i></a>
                                            <a href="#" class="social"><i class="fab fa-google-plus-g"></i></a>
                                            <a href="#" class="social"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                        <input type="text" placeholder="Name *" />
                                        <input type="email" placeholder="Email *" />
                                        <input type="text" placeholder="Company" />
                                        <input type="phone" placeholder="Phone" />
                                        <textarea rows="4" placeholder="Message"></textarea>
                                        <button>Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>

        </div>

    )
}

export default Partner;
