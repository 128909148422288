import React, { useEffect } from 'react';
import { Container, Col, Row, Button, Card } from 'react-bootstrap';
import "./home.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Carousel from '../../Components/Carousel/carousel';
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

function Home() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    const img = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,

        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },

            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],


    };


    return (
        <div>

            <Carousel />
            <div className='home'>
                <Container>
                    <p className='homepara'>
                        Provide one stop solution to the Mid-corporates,MSMEs and business entities in financial
                        management, accounting, tax compliance and Finance requirements to enable the entrepreneur devote
                        all his time for the development of business. We support the business through digital marketing to have
                        more outreach on focused customers.
                    </p>
                    <h6 className='home1h6'>
                        Why Fimags to be chosen
                    </h6>
                    <p className='homepara'>
                        1. Provides all Financial services under one umbrella .<br></br>
                        2. Managed by the team of well experienced Finance professionals and Banking experts.<br></br>
                        3. Handholding from the starting of business for maximum productivity and Profitability.
                    </p>
                </Container>

            </div>


            <div className='aboutus'>
                <Container>
                    <h2>About Us</h2>

                    <div className='aboutus-text'>
                        <p>The company is founded in 2017 by a group of Experienced bankers and Finance professionals to provide Advisory services in the financial management and Business counselling for mid-corporates

                            MSMEs and other businesses.</p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <Link to="/aboutus"> <Button variant="success">Know More</Button>{' '}</Link>
                    </div>

                </Container>
            </div>


            <div className='servicess'>
                <Container>
                    <h2>Our Services</h2>

                    <div className='slidertop'>
                        <Slider {...img}>
                            <div>
                                <Card >
                                    <Card.Img variant="top" src={require('../../assets/finance.jpg')} />
                                    <Card.Body>
                                        <Card.Title>Financial Information Services</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div>
                                <Card >
                                    <Card.Img variant="top" src={require('../../assets/advisory.jpg')} />
                                    <Card.Body>
                                        <Card.Title>Financial advisory services</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div>
                                <Card >
                                    <Card.Img variant="top" src={require('../../assets/intermediary_img.jpg')} />
                                    <Card.Body>
                                        <Card.Title>Financial Intermediary</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div>
                                <Card >
                                    <Card.Img variant="top" src={require('../../assets/accounting.jpg')} />
                                    <Card.Body>
                                        <Card.Title>Accounting services</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div>
                                <Card >
                                    <Card.Img variant="top" src={require('../../assets/tax.jpg')} />
                                    <Card.Body>
                                        <Card.Title>Tax consultation and compliance</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div>
                                <Card >
                                    <Card.Img variant="top" src={require('../../assets/business consultant.jpeg')} />
                                    <Card.Body>
                                        <Card.Title>Business consultation</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>

                        </Slider>
                    </div>

                    <div style={{ textAlign: "center" }}>
                        <Link to='/services'><Button variant="success">View All</Button>{' '}</Link>
                    </div>

                </Container>
            </div>




            <div className='servicess advisory'>
                <Container>
                    <h2>NPA Advisory</h2>

                    <div className='slidertop'>
                        <Slider {...img}>
                            <div>
                                <Card >
                                    <Card.Img variant="top" src={require('../../assets/banners1.png')} />
                                    <Card.Body>
                                        <Card.Title>NPA Management</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div>
                                <Card >
                                    <Card.Img variant="top" src={require('../../assets/accounting.jpg')} />
                                    <Card.Body>
                                        <Card.Title>Mergers and acquisitions</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div>
                                <Card >
                                    <Card.Img variant="top" src={require('../../assets/business consultant.jpeg')} />
                                    <Card.Body>
                                        <Card.Title>Short Term Finance</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div>
                                <Card >
                                    <Card.Img variant="top" src={require('../../assets/advisory.jpg')} />
                                    <Card.Body>
                                        <Card.Title>Advise for recovery suits</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div>
                                <Card >
                                    <Card.Img variant="top" src={require('../../assets/court.jpg')} />
                                    <Card.Body>
                                        <Card.Title>Advise for SARFAESI/DRT/NCLT cases</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>


                        </Slider>
                    </div>

                    <div style={{ textAlign: "center" }}>
                        <Link to='/npa-advisory'><Button variant="success">View All</Button>{' '}</Link>
                    </div>

                </Container>


            </div>


            <div className='aboutus wealth'>
                <Container>
                    <h2>Virtual CFO</h2>

                    <div className='aboutus-text' style={{ textAlign: "left" }}>

                        <p>Chief Finance officer role in corporates and MSMEs is very important to scale new heights but it is not affordable for Small & Mid Corporates and MSMEs when they  are in growing stage, . We offer entire gamut of CFO services with, <br></br>

                            <ul style={{ marginTop: "10px" }}>
                                <li>Financial planning</li>
                                <li>Financial Management/Banking</li>
                                <li>Accounts & Audit requirements </li>
                                <li>Cost savings</li>
                                <li>Tax compliance</li>
                            </ul>

                            Our Virtual CFO is virtually in your office taking care of financial management and meeting whenever necessary. The entrepreneur can concentrate more on development of business leaving the financial worries to our Virtual CFO.

                        </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <Link to='/virutual-cfo'> <Button variant="success">Know More</Button>{' '}</Link>
                    </div>

                </Container>
            </div>


        </div>

    )
}

export default Home
