import React from 'react';
import {Container, Col, Row} from 'react-bootstrap';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/home";
import Services  from "./Pages/Services/services";
import Aboutus  from "./Pages/Aboutus/aboutus";
import Virutual  from "./Pages/Virutual/virutual";
import Npa  from "./Pages/Npa/npa";
import Wealth  from "./Pages/Wealth/wealth";
import Agri  from "./Pages/Agri/agri";
import Contact  from "./Pages/Contactus/contactus";
import Partner  from "./Pages/Partner/partner";

import Header from './Layouts/Header/header';
import Footer from './Layouts/Footer/footer';


function App() {
  return (
    <>    
    <BrowserRouter>
     <Header/>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/aboutus" element={<Aboutus />}/>
        <Route path="/services" element={<Services />}/>
        <Route path="/virutual-cfo" element={<Virutual />}/>
        <Route path="/npa-advisory" element={<Npa />}/>
        <Route path="/wealth-creation" element={<Wealth />}/>
        <Route path="/agri-solutions" element={<Agri />}/>
        <Route path="/contactus" element={<Contact />}/>
        <Route path="/partner-with-us" element={<Partner />}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
    
    
    </>
  )
}

export default App
