import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import "./contactus.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BsFillPersonFill } from 'react-icons/bs';
import { BsTelephonePlus } from 'react-icons/bs';
import { MdOutlineMailOutline } from 'react-icons/md';
import { MdLocationPin } from 'react-icons/md';
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from '@emailjs/browser';
function Contact() {

    const initialValues = { username: "", email: "", phonenumber: "", address: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e, type) => {


        console.log("dsfsdf", e.target.value)

        if (type == "username") {
            var data = { ...formValues }
            data.username = e.target.value
            setFormValues(data)
        }
        else if (type == "email") {
            var data = { ...formValues }
            data.email = e.target.value
            setFormValues(data)
        }
        else if (type == "phonenumber") {
            var data = { ...formValues }
            data.phonenumber = e.target.value
            setFormValues(data)
        }
        else if (type == "address") {
            var data = { ...formValues }
            data.address = e.target.value
            setFormValues(data)
        }
        // setFormValues({ ...formValues, [name]: value });
    };


    // const handleSubmit = () =>
    // {
    // const handleSubmit = (event) => {
    //     const templateId = 'template_dpvc1jo';
    //    sendFeedback(templateId, {message_html: "sasssssssssssssiiiiiiiiii", 
    //     from_name: "thasssssssssssssss", reply_to: "info@fimagscorporate.com"})
    //   }
    //   const  sendFeedback = (templateId, variables)=> {
    //     window.emailjs.send(
    //       'gmail', templateId,
    //       variables
    //       ).then(res => {
    //         console.log('Email successfully sent!',res)
    //       })
    //       // Handle errors here however you like, or use a React error boundary
    //       .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    //   }
    // }
    const handleSubmit = (e) => {
        // window.open('mailto:info@fimagscorporate.com?subject='+"sasisiiiiii"+ "&body="+"thasssssssssss");

      
        // e.preventDefault();
        setFormErrors(validate(formValues));
        if (formValues.address && formValues.phonenumber && formValues.username && formValues.email) {
            const templateParams = {
                username: formValues.username,
                address: formValues.address,
                email:formValues.email,
                phonenum:formValues.phonenumber
            };
            emailjs.send('service_n8kml287', 'template_8agoa4a', templateParams, 'f8eZM-pYGlWPBsh7Q')
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                }, (err) => {
                    console.log('FAILED...', err);
                });
            // var data123 = {...formValues}
            //  data123.address="",
            //  data123.email="",
            //  data123.phonenumber='',
            //  data123.username=''
            //  setFormValues(data123)


            swal({
                title: "Send the Request Successfully.",
                icon: "success",

            }).then(function () {
                window.location.reload()
            });

        }


        // setIsSubmit(true);
    };

    // useEffect(() => {
    //     console.log(formErrors);
    //     if (Object.keys(formErrors).length === 0 && isSubmit) {
    //         console.log(formValues);
    //     }
    // }, [formErrors]);



    const validate = (values) => {

        console.log(values, "bbbbbbbbbbbb")

        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        // const regex1 =  new RegExp(/"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"/gmi);

        if (!values.username) {
            errors.username = "Username is required!";
        }
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        if (!values.phonenumber) {
            errors.phonenumber = "Phonenumber is required!";
        }
        // if (!values.phonenumber) {
        //     errors.phonenumber = "Phone Number is required!";
        // } else if (!regex1.test(values.phonenumber)) {
        //     errors.phonenumber = "This is not a valid Mobile Number!";
        // }

        if (!values.address) {
            errors.address = "Address is required!";
        }

        return errors;
    };


    const key = "6Le5z1olAAAAAIWBdv2B_hEWIs6JorWqVVYNK-sD";
    const [captchavalue, setcaptchavalue] = useState(false);

    function onChangerecaptcha(value) {
        console.log("Captcha value");
        setcaptchavalue(true);
    }


    useEffect(() => {
        AOS.init({
            duration: 2000
        });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    return (
        <div>

            <div className='contactbanner'>
                <h2>Contact Us</h2>
            </div>

            <div className='backgroundcolor'>

                <div className='virutual'>

                    <Container>
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <div>
                                    <form  >
                                        <h1 class="title text-center mb-4">Talk to Us</h1>


                                        <div class="form-group position-relative has-validation">
                                            <label for="formName" class="d-block">
                                                {/* <i class="fa fa-square"></i> */}
                                                <BsFillPersonFill className='iconzz' />
                                            </label>
                                            <input type="text" id="formName" name="username" class="form-control form-control-lg thick" placeholder="Name" value={formValues.username}
                                                onChange={(e) => handleChange(e, "username")} />
                                            <p className='errormsg'>{formErrors.username}</p>
                                        </div>


                                        <div class="form-group position-relative">
                                            <label for="formEmail" class="d-block">
                                                {/* <i class="icon" data-feather="mail"></i> */}
                                                <MdOutlineMailOutline className='iconzz' />
                                            </label>
                                            <input type="email" id="formEmail" name="email" class="form-control form-control-lg thick" placeholder="E-mail" value={formValues.email}
                                                onChange={(e) => handleChange(e, "email")} />

                                            <p className='errormsg'>{formErrors.email}</p>
                                        </div>

                                        <div class="form-group position-relative">
                                            <label for="formEmail" class="d-block">
                                                {/* <i class="icon" data-feather="mail"></i> */}
                                                <BsTelephonePlus className='iconzz' />
                                            </label>
                                            <input type="text" id="formphonenumber" name="phonenumber" class="form-control form-control-lg thick" placeholder="Phone Number" value={formValues.phonenumber}
                                                onChange={(e) => handleChange(e, "phonenumber")} />

                                            <p className='errormsg'>{formErrors.phonenumber}</p>
                                        </div>

                                        <div class="form-group position-relative">
                                            <label for="formEmail" class="d-block">
                                                {/* <i class="icon" data-feather="mail"></i> */}
                                                <MdLocationPin className='iconzz' />
                                            </label>
                                            <input type="text" id="formaddress" name="message" class="form-control form-control-lg thick" rows="4" placeholder="Address" value={formValues.address}
                                                onChange={(e) => handleChange(e, "address")} />

                                            <p className='errormsg'>{formErrors.address}</p>
                                        </div>






                                        <ReCAPTCHA
                                            sitekey={key}
                                            onChange={onChangerecaptcha}
                                        />
                                        {/* <div class="form-group message">
				<textarea id="formMessage" class="form-control form-control-lg" rows="5" placeholder="Address"></textarea>
			</div> */}


                                        <div class="text-center">
                                            <button onClick={handleSubmit} type="button" class="btn btn-primary" tabIndex="-1">Send message</button>
                                        </div>
                                    </form>

                                </div>
                            </Col>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <img style={{ width: "100%", borderRadius: "10px" }} src={require("../../assets/Contact-1.gif")} />
                            </Col>



                        </Row>
                    </Container>



                </div>



            </div>



        </div>


    )
}

export default Contact;
