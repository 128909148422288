import React, { useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import "./wealth.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


function Services() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    return (
        <div>

            <div className='wealthbanner'>
                <h2>Wealth Creation</h2>
            </div>

            <div className='backgroundcolor'>

                <div className='virutual'>
                    <Container>
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <div>
                                    <h3>Wealth Creation</h3>
                                    <p>Finance experts provide valuable tips for multiplication of wealth by identifying suitability of the product  with minimal risks based on the requirement of the investor.<br></br>
Bank deposits, Corporate Bonds & deposits, mutual funds, Portfolio management and Insurance products offered through tie-ups with respective institutions.<br></br>
Identifying projects and Alternative investment options for investors for maxi returns


                                    </p>
                                </div>
                            </Col>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <img style={{ width: "100%", borderRadius:"10px" }} src={require("../../assets/wealth.jpg")} />
                            </Col>
                        </Row>
                    </Container>
                </div>


            </div>

        </div>

    )
}

export default Services;
