import React, { useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import "./agri.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


function Services() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    return (
        <div>

            <div className='agribanner'>
                <h2>Agri Solutions</h2>
            </div>

            <div className='backgroundcolor'>

                <div className='virutual'>
                    <Container>
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <div>
                                    <h3>Agri Solutions</h3>
                                    <p>Platform to Agripreneur for their Value addition, Processing,  Storing, Finance, Direct marketing and other technical assistance. <br></br>
                                        Enabling meeting of farmers, processors and buyers through the platform for the benefit to all.



                                    </p>
                                </div>
                            </Col>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <img style={{ width: "100%", borderRadius: "10px" }} src={require("../../assets/grains1.jpg")} />
                            </Col>
                        </Row>
                    </Container>
                </div>


            </div>

        </div>

    )
}

export default Services;
