import React from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import "./header.css";
import { HashLink as Link } from 'react-router-hash-link';

import { NavLink } from 'react-router-dom';


function Header() {
  return (
    <div>
      <div style={{ padding: "10px 0px" }}>
        <Container fluid={true}>
          <Row style={{ alignItems: "center" }}>
            <Col md={4} xs={4}>
              <Link to="/"><img src={require("../../assets/logo.png")} /></Link>
            </Col>
            <Col md={8} xs={8}>
              <div>
                <h2 className='heading'>FIMAGS CORPORATE ADVISORS P LTD</h2>
              </div>
            </Col>
          </Row>

        </Container>
      </div>

      <Navbar bg="light" expand="lg">
        <Container fluid={true}>
          {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-autoo">
              <NavLink to="/" activeClassName="active">Home</NavLink>
              <NavLink to="/aboutus" activeClassName="active">About Us</NavLink>
              <NavLink to="/virutual-cfo" activeClassName="active">Virtual CFO</NavLink>
              <NavDropdown title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item style={{ color:"black" }} activeClassName="active" href="/services">Financial Information Services </NavDropdown.Item>
                <NavDropdown.Item style={{ color:"black" }} href="/services#advisory">Financial advisory services </NavDropdown.Item>
                <NavDropdown.Item style={{ color:"black" }} href="/services#Intermediary">Financial Intermediary </NavDropdown.Item>
                <NavDropdown.Item style={{ color:"black" }} href="/services#Accounting">Accounting services </NavDropdown.Item>
                <NavDropdown.Item style={{ color:"black" }} href="/services#Tax">Tax consultation and compliance </NavDropdown.Item>
                <NavDropdown.Item style={{ color:"black" }} href="/services#consultation">Business consultation </NavDropdown.Item>
                <NavDropdown.Item style={{ color:"black" }} href="/services#Startup">Startup advisory </NavDropdown.Item>
              </NavDropdown>
              <NavLink to="/npa-advisory" activeClassName="active">NPA Advisory</NavLink>
              <NavLink to="/wealth-creation" activeClassName="active">Wealth Creation</NavLink>
              <NavLink to="/agri-solutions" activeClassName="active">Agri Solutions</NavLink>
              <NavLink to="/contactus" activeClassName="active">Contact Us</NavLink>
              <NavLink to="/partner-with-us" activeClassName="active">Partner with us</NavLink>
              {/* <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/aboutus">About Us</Nav.Link>
              <NavLink href="/virutual-cfo">Virutual CFO</NavLink>
              <NavDropdown title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item href="/services">Financial Information Services </NavDropdown.Item>
                <NavDropdown.Item href="/services#advisory">Financial advisory services </NavDropdown.Item>
                <NavDropdown.Item href="/services#Intermediary">Financial Intermediary </NavDropdown.Item>
                <NavDropdown.Item href="/services#Accounting">Accounting services </NavDropdown.Item>
                <NavDropdown.Item href="/services#Tax">Tax consultation and compliance </NavDropdown.Item>
                <NavDropdown.Item href="/services#consultation">Business consultation </NavDropdown.Item>
                <NavDropdown.Item href="/services#Startup">Startup advisory </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/npa-advisory">NPA Advisory</Nav.Link>
              <Nav.Link href="/wealth-creation">Wealth Creation</Nav.Link>
              <Nav.Link href="/agri-solutions">Agri Solutions </Nav.Link>
              <Nav.Link href="/contactus">Contact Us</Nav.Link>
              <Nav.Link href="/partner-with-us">Partner with us</Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


    </div>
  )
}

export default Header
