import React, { useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import "./npa.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


function Npa() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    return (
        <div>

            <div className='npabanner'>
                <h2>NPA Advisory</h2>
            </div>

            <div className='backgroundcolor'>

                <div className='virutual'>
                    <Container>
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <div>
                                    <h3>NPA Management</h3>
                                    <p>
                                    Our team of experts provide advise and services for the revival and rehabilitation of the business or industry and to take steps to come out of NPA. One Time Settlement preparation and Negotiation done.


                                    </p>
                                </div>
                            </Col>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <img style={{ width: "100%" }} src={require("../../assets/npa-advisory.jpg")} />
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className='virutual virutual1'>
                    <Container>
                        <Row style={{ alignItems: "center" }}>
                        <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <img style={{ width: "100%" }} src={require("../../assets/advicee.jpg")} />
                            </Col>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <div>
                                    <h3>Advise for recovery suits/DRT/SARFAESI/NCLT</h3>
                                    <p>
                                    Our team of experts provide advise and services for the revival and rehabilitation of the business or industry and to take steps to come out of NPA. One Time Settlement preparation and Negotiation done.


                                    </p>
                                </div>
                            </Col>
                           
                        </Row>
                    </Container>
                </div>

                <div className='virutual'>
                    <Container>
                        <Row style={{ alignItems: "center" }}>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <div>
                                    <h3>Mergers and acquisitions</h3>
                                    <p>
                                    Our team explore the possibility of bringing new investor for take over as well as the Mergers and acquisitions that suits the business and implement it on suitable terms.
<br></br><br></br>
                                   
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <img style={{ width: "100%" }} src={require("../../assets/merge.png")} />
                            </Col>
                        </Row>
                    </Container>
                </div>


                <div className='virutual virutual1'>
                    <Container>
                        <Row style={{ alignItems: "center" }}>
                        <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <img style={{ width: "100%" }} src={require("../../assets/shorttermfinance.png")} />
                            </Col>
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <div>
                                    <h3>Short term finance</h3>
                                    <p>
                                    Short term finance to avoid loan account/credit limit becoming NPA arranged.


                                    </p>
                                </div>
                            </Col>
                           
                        </Row>
                    </Container>
                </div>


            </div>

        </div>

    )
}

export default Npa;
