import React, { useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import "./carousel.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Carousel from 'react-bootstrap/Carousel';



function Carouselz() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);


    return (
        <div>

            <Carousel data-interval="100">
                <Carousel.Item>
                    <img className="d-block w-100"  src={require('../../assets/banners.png')} />
                    <Carousel.Caption>
                       <div className='caption-text'>
                        <p>" TURNING YOUR VISION INTO VALUE "</p>
                       </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100"   src={require('../../assets/banners1.png')} />

                    <Carousel.Caption>
                    <div className='caption-text'>
                        <p>" TURNING YOUR VISION INTO VALUE "</p>
                       </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default Carouselz;