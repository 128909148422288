import React, { useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import "./services.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


function Services() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }, []);


    return (
        <div>

            <div className='servicebanner'>
                <h2>Our Services</h2>
            </div>

            <div className='backgroundcolor'>

            <div className='services1' id="Information">
                <Container>
                    <Row style={{ alignItems:"center" }}>
                        <Col md={6} data-aos="fade-up" data-aos-duration="3000">
                           <div>
                            <h4>Financial Information Services</h4>
                            <p>Creating financial awareness and providing the latest financial and market information to our clients and public thro’ our site ,social media and conducting seminars.</p>
                           </div>
                        </Col>
                        <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                            <img style={{ width:"100%" }} src={require("../../assets/finance.jpg")}/>
                       </Col>
                    </Row>
                </Container>
            </div>

            <div className='services' id="advisory">
                <Container>
                    <Row style={{ alignItems:"center" }}>
                    <Col md={6} id="#done" data-aos="fade-down" data-aos-duration="3000">
                            <img style={{ width:"100%" }} src={require("../../assets/advisory.jpg")}/>
                       </Col>
                        <Col md={6} data-aos="fade-up" data-aos-duration="3000">
                           <div>
                            <h4>Financial advisory services</h4>
                            <p>Advising the client on Banking,Finance , cost savings to attain maximum profitability and productivity. </p>
                           </div>
                        </Col>
                       
                    </Row>
                </Container>
            </div>

            <div className='services1' id="Intermediary">
                <Container>
                    <Row style={{ alignItems:"center" }}>
                        <Col md={6} data-aos="fade-up" data-aos-duration="3000">
                           <div>
                            <h4>Financial Intermediary</h4>
                            <p>Through the tie-up with banks/NBFC/ Other financial institutions arrange for required finance for the business/industry and placing investments in various avenues.</p>
                           <h5>Project finance</h5>
                           <p>From drawing Detailed Project report to arranging finance/investment undertaken</p>
                           </div>
                        </Col>
                        <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                            <img style={{ width:"100%", borderRadius:"14px" }} src={require("../../assets/venuee.jpg")}/>
                       </Col>
                    </Row>
                </Container>
            </div>

            <div className='services' id="Accounting">
                <Container>
                    <Row style={{ alignItems:"center" }}>
                    <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                            <img style={{ width:"100%" }} src={require("../../assets/accounting.jpg")}/>
                       </Col>
                        <Col md={6} data-aos="fade-up" data-aos-duration="3000">
                           <div>
                            <h4>Accounting services</h4>
                            <p>Providing the services of accounts department and Generation of Financial  statements to be submitted for Auditors and tax authorities </p>
                           </div>
                        </Col>
                      
                    </Row>
                </Container>
            </div>

            <div className='services1' id="Tax">
                <Container>
                    <Row style={{ alignItems:"center" }}>
                        <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                           <div>
                            <h4>Tax consultation and compliance</h4>
                            <p>IT consultation, Income Tax returns filing, TDS matters , GST consultation and filing of GST returns are undertaken.</p>
                           </div>
                        </Col>
                        <Col md={6} data-aos="fade-up" data-aos-duration="3000">
                            <img style={{ width:"100%" }} src={require("../../assets/tax.jpg")}/>
                       </Col>
                    </Row>
                </Container>
            </div>

            <div className='services' id="consultation">
                <Container>
                    <Row style={{ alignItems:"center" }}>
                    <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                            <img style={{ width:"100%" }} src={require("../../assets/business consultant.jpeg")}/>
                       </Col>
                        <Col md={6} data-aos="fade-up" data-aos-duration="3000">
                           <div>
                            <h4>Business consultation</h4>
                            <p>Advising and Handholding from starting of the business to running profitabily.Company formation, trusts formation, undertaken.
                                <br></br>
                                 

                            </p>
                            <p>Advising on Govt. policies,Programmes and available subsidies and concessions</p>
                            <p>Identication of markets and promotion through digital marketing undertaken. </p>
                           </div>
                        </Col>
                      
                    </Row>
                </Container>
            </div>

            <div className='services1' id="Startup">
                <Container>
                    <Row style={{ alignItems:"center" }}>
                        <Col md={6} data-aos="fade-up" data-aos-duration="3000">
                           <div>
                            <h4>Startup advisory</h4>
                            {/* <p>Study and analyse the feasibility of the project and take up with investor</p> */}
                            <p>Study and analyse the feasibility of the project and take up with investor.
                                <br></br><br></br>
                                Arranging investment(seed fund) on suitable terms initially and further rounds. The mergers and acquisition arranged on favourable terms for mutual benefit. </p>
                                
                           </div>
                        </Col>
                        <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                            <img style={{ width:"100%" }} src={require("../../assets/startup advisor.jpg")}/>
                       </Col>
                    </Row>
                </Container>
            </div>
            </div>

        </div>

    )
}

export default Services;
